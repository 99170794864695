import React from 'react';
import { SvgIcon, SvgIconProps } from '../../wag-react/next-components/icons';
type WalkIconProps = SvgIconProps;
export const WalkIcon = (props: WalkIconProps) => <SvgIcon viewBox="0 0 37 40" {...props} data-sentry-element="SvgIcon" data-sentry-component="WalkIcon" data-sentry-source-file="WalkIcon.tsx">
    <g fill="none" fillRule="evenodd" data-sentry-element="g" data-sentry-source-file="WalkIcon.tsx">
      <path d="M-6-4h48v48H-6z" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
      <path opacity=".16" d="M-3-1h42v42H-3z" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
      <g transform="translate(0 .5)" data-sentry-element="g" data-sentry-source-file="WalkIcon.tsx">
        <g fillRule="nonzero" data-sentry-element="g" data-sentry-source-file="WalkIcon.tsx">
          <path d="M27.14 27.366s.951 4.055 3.007 4.278c2.055.223 3.802.066 4.99.903.358.237.666.54.91.891 0 0 0-1.877-1.15-2.395s-3.353-.476-3.64-1.628c-.288-1.153-.728-3.482-.526-3.96.202-.477-4.052-.07-3.59 1.911zM16.398 36.708s-1.568.221-2.198-.427c-.63-.65-.28-2.106-.654-3.01-.515-1.187-3.22-3.949-3.22-3.949l3.508-.361c.499 1.106.94 2.238 1.319 3.391.356 1.262-.475 2.215-.112 3.09a2.476 2.476 0 001.357 1.266zM6.003 23.737s-2.139-.162-3.289-.889C1.426 22.045 0 19.25 0 19.25s.428 3.491 1.704 4.696c1.276 1.205 3.42 1.597 3.42 1.597l.88-1.806zM23.849 29.68a30.788 30.788 0 01-2.17 4.084 12.19 12.19 0 004.61-1.41 7.367 7.367 0 01-2.44-2.673z" fill="#000" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
          <path d="M30.921 24.97c-.076-2.262-1.214-7.859-1.214-7.859l-9.865 1.497s-1.616 2.14-3.803 2.817c-2.186.677-8.018.677-9.54 1.409-1.294.616-3.172 2.959-2.347 5.48.825 2.522 5.062 2.213 5.307 1.688.206-.433.52-.528 1.877-.12 1.357.41 6.248 3.566 7.605 3.853a9.56 9.56 0 002.719.055 30.342 30.342 0 002.17-4.083 7.367 7.367 0 002.44 2.693c.512-.28.998-.604 1.453-.97 1.15-.927 3.272-4.19 3.198-6.46z" fill="#2E2829" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
          <path d="M10.374 28.457s-.713 5.668-3.86 5.804c-2.222.09-2.852.306-3.434.76-.582.454-.596 1.26-2.474 1.212 0 0 .644-.333 1.226-1.977.583-1.645 1.146-2.02 2.013-2.118.868-.097.326-2.664.212-4.228-.114-1.563 5.513-3.146 6.317.547zM27.188 28.944s-2.241 5.635-2.885 7.367a3.368 3.368 0 00.311 3.019s-1.635-.224-1.88-2.489c-.245-2.264.19-3.362-.088-5.228-.278-1.866 1.954-5.594 4.542-2.669z" fill="#2E2829" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        </g>
        <ellipse fill="#F9CD46" fillRule="nonzero" transform="rotate(-60 33.515 23.538)" cx="33.515" cy="23.538" rx="1.362" ry="2.179" data-sentry-element="ellipse" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M18.982 19.474c1.63 1.31 3.042 2.186 4.237 2.628 1.79.658 2.158.756 3.886.727 1.153-.019 2.302-.508 3.446-1.468l.28 1.93c-1.05.978-2.193 1.467-3.427 1.466-1.854 0-2.754-.065-4.784-.951-2.03-.886-4.337-2.833-4.796-3.417.416-.324.801-.63 1.158-.915z" fill="#7786B7" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M20.289 8.128S16.486 3.98 13.66 3.02c-2.825-.96-2.212.306-1.991 1.494.22 1.189 3.244 9.447 4.254 10.037 1.01.59 4.359-6.417 4.359-6.417" fill="#2D2829" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M20.289 8.128S16.486 3.98 13.66 3.02c-2.825-.96-1.984.639-.92 1.202 1.56.825 3.93 3.249 5.835 5.882.972-1.076 1.714-1.977 1.714-1.977M23.63 6.654s-.325-2.956.238-4.736c.563-1.78 1.84-3.178 3.225-.11 1.385 3.069.587 4.663 1.072 5.395a20.84 20.84 0 001.031 1.407L23.63 6.654z" fill="#000" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M27.806 22.46c.713-.21 1.709-1.663 2.31-1.9.601-.238 1.54-.316 2.084-.606s.95-1.246.95-1.246a.95.95 0 00.951-.12c.533-.372.564-2.069.145-2.915-.418-.846-1.609-1.72-1.823-2.521-.213-.801-.328-2.614-.84-3.21a9.466 9.466 0 00-3.59-2.44c-1.69-.598-3.602-1.138-4.496-.898a12.69 12.69 0 00-1.664.587l-3.058 4.737 3.997 9.397 2.332.9s2.003.45 2.704.238" fill="#FFF" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M25.717 22.192c.727-.95-.604-2.9-.88-3.854-.408-1.426-.546-3.09-.064-4.516.18-.532.713-1.269.713-1.837a7.481 7.481 0 00-.506-2.3c-.409-1.127-1.267-2.061-2.139-2.89-.97.28-1.9.686-2.766 1.207-.872.52-3.914 4.301-4.11 6.074-.194 1.773 1.664 3.377 2.636 4.84.972 1.46 4.516 3.283 5.566 3.288.492.02.983.069 1.469.145.027-.053.06-.104.095-.152" fill="#000" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M31.948 10.034c.454.75.414 1.611-.095 1.918-.509.306-1.288-.055-1.742-.808-.454-.754-.413-1.612.093-1.918.506-.307 1.288.054 1.744.808M25.403 12.232c.288 1.072-.44 2.196-1.62 2.515-1.182.318-2.377-.297-2.662-1.367-.286-1.07.44-2.198 1.62-2.517 1.181-.318 2.377.297 2.662 1.37" fill="#818080" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M24.628 12.553c.136.506-.29 1.06-.95 1.236-.661.176-1.305-.09-1.44-.597-.136-.506.287-1.06.95-1.238.663-.178 1.305.093 1.44.599" fill="#000" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M24.174 12.327a.4.4 0 11-.451-.185.35.35 0 01.451.185" fill="#FFF" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M32.038 10.355a.844.844 0 110-.003" fill="#000" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M31.649 10.162a.302.302 0 11-.34-.14.269.269 0 01.34.14" fill="#FFF" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M28.485 11.429c.904-.28 1.678.171 2.223.66.506.457.879 1.282 1.202 1.769.323.487.991 1.026 1.426 1.725.435.699.98 2.954.649 3.102-1.35.589-.9-1.189-1.88-1.058-.442.062-2.089 2.125-2.992 2.766-.796.564-3.584 1.426-3.584 1.426s2.724-1.188 3.349-2.03c.399-.536.629-1.18.66-1.848.024-.915-.25-1.471-1.062-2.467-.78-.95-1.48-1.077-1.642-2.346-.067-.535.596-1.36 1.663-1.687" fill="#818080" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <path d="M30.641 12.648c.071.454-.542.927-1.374 1.06-.831.133-1.563-.128-1.637-.582-.074-.454.544-.927 1.376-1.06.832-.133 1.564.128 1.635.582" fill="#000" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        <g fillRule="nonzero" data-sentry-element="g" data-sentry-source-file="WalkIcon.tsx">
          <path d="M34.013 17.784c-.454-.307-.606-.178-1.007-1.39-.266.297-.699-.036-1.022.168-.393.287-.75.62-1.063.994a3.95 3.95 0 00-.263-.312c.76.766.77 1.555.95 1.823.112.181.265.333.447.442a1.901 1.901 0 001.633.02 1.53 1.53 0 00.713-.65c.268-.427.06-.805-.388-1.095z" fill="#AC676D" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
          <path d="M32.711 16.524a.67.67 0 00-.756.079c-.43.301-.867.454-.104.639s1.59 1.616 1.59 1.616a8.08 8.08 0 01-.73-2.139c-.026-.492 0-.195 0-.195z" fill="#322631" opacity=".5" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
        </g>
        <path d="M32.62 15.85a6.471 6.471 0 00-1.62-.611s.677.734.287 1.463c-.15.276-1.01 1.785-1.16 2.118a3.09 3.09 0 01-1.939 1.542 2.557 2.557 0 002.156-1.45c.459-.805 1.05-1.722 1.637-2.081.497-.304.984-.219 1.134 0 .335-.314-.43-.884-.494-.982z" fill="#241F20" fillRule="nonzero" data-sentry-element="path" data-sentry-source-file="WalkIcon.tsx" />
      </g>
    </g>
  </SvgIcon>;