import React, { ReactNode } from 'react';
import Head from 'next/head';
type TwitterMeta = Partial<Record<'title' | 'description' | 'image' | 'creator' | 'card' | 'site', string>>;
type OpenGraphMeta = Partial<Record<'title' | 'type' | 'image' | 'url' | 'description' | 'siteName' | 'type' | 'url', string>>;
export type HelmetProps = {
  // Required
  title: string;
  canonical: string;
  description: string;
} & Partial<{
  // Optional
  robots: 'index, follow' | 'noindex, follow' | 'index, nofollow' | 'noindex, nofollow';
  viewport: string;
  twitter: TwitterMeta;
  openGraph: OpenGraphMeta;
  prevPageUrl: string;
  nextPageUrl: string;
  children: ReactNode;
}>;
const TwitterMetaName = {
  Title: 'twitter:title',
  Description: 'twitter:description',
  Image: 'twitter:image',
  Creator: 'twitter:creator',
  Site: 'twitter:site',
  Card: 'twitter:card'
};
const OpenGraphMetaName = {
  Title: 'og:title',
  Description: 'og:description',
  Image: 'og:image',
  SiteName: 'og:site_name',
  Type: 'og:type',
  Url: 'og:url'
};
const MetaNameLookup = {
  Canonical: 'canonical',
  Viewport: 'viewport',
  Description: 'description',
  Robots: 'robots',
  Twitter: TwitterMetaName,
  OpenGraph: OpenGraphMetaName
};
export const Helmet = ({
  title,
  canonical,
  description,
  robots,
  viewport = 'width=device-width, initial-scale=1.0',
  twitter,
  openGraph,
  prevPageUrl,
  nextPageUrl,
  children
}: HelmetProps) => <>
    <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
      <title>{title}</title>
      <link rel={MetaNameLookup.Canonical} href={canonical} />
      <meta name={MetaNameLookup.Viewport} content={viewport} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      {/* 
        Disables converting phone numbers, dates, emails and addresses to links on iOS 
        to prevent hydration mismatches.
       */}
      <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name={MetaNameLookup.Description} content={description} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name={MetaNameLookup.Robots} content={robots} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      {twitter && <>
          <meta name={MetaNameLookup.Twitter.Title} content={twitter.title} />
          <meta name={MetaNameLookup.Twitter.Description} content={twitter.description} />
          <meta name={MetaNameLookup.Twitter.Image} content={twitter.image} />
          <meta name={MetaNameLookup.Twitter.Site} content={twitter.site} />
          <meta name={MetaNameLookup.Twitter.Creator} content={twitter.creator} />
          <meta name={MetaNameLookup.Twitter.Card} content={twitter.card} />
        </>}
      {openGraph && <>
          <meta property={MetaNameLookup.OpenGraph.Title} content={openGraph.title} />
          <meta property={MetaNameLookup.OpenGraph.Description} content={openGraph.description} />
          <meta property={MetaNameLookup.OpenGraph.Image} content={openGraph.image} />
          <meta property={MetaNameLookup.OpenGraph.SiteName} content={openGraph.siteName} />
          <meta property={MetaNameLookup.OpenGraph.Type} content={openGraph.type} />
          <meta property={MetaNameLookup.OpenGraph.Url} content={openGraph.url} />
        </>}
      {prevPageUrl && <link rel="prev" href={prevPageUrl} />}
      {nextPageUrl && <link rel="next" href={nextPageUrl} />}
    </Head>
    {/*
      Do not render outside <Head />
      When we built this Helmet component, we envision that
      all related head elements (i.e link, script) will go inside this component.
      However, in Next.JS v11.1 script elements should now be moved outside the <Head />
      component so that loading strategies will work.
     */}
    {children}
  </>;