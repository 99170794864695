import React from 'react';
import { LI } from '../core-components/li';
import { Link } from '../core-components/link';
import { OL } from '../core-components/ol';
import { UL } from '../core-components/ul';
import { BoxProps } from '../next-components/box';
import { ChevronRightIcon } from '../next-components/icons';
import { Typography, TypographyProps } from './Typography';
export type Breadcrumb = {
  url?: string; // Optional, this should be omitted from the last breadcrumb in the list
  title: string;
};
export type BreadcrumbsProps = {
  list: Breadcrumb[];
};
export type Props = BreadcrumbsProps & Omit<BoxProps, 'list'> & {
  variant: 'ol' | 'ul';
  listItemProps?: BoxProps;
  titleProps?: TypographyProps;
};
export const Breadcrumbs = ({
  list,
  variant,
  listItemProps,
  titleProps,
  ...rest
}: Props) => {
  const Component = variant === 'ol' ? OL : UL;
  return <Component p={0} {...rest as Record<string, unknown>} data-sentry-element="Component" data-sentry-component="Breadcrumbs" data-sentry-source-file="Breadcrumbs.tsx">
      {list.map((item, index) => <LI key={item.title || item.url} display="inline-flex" alignItems="center" color="medGray" {...listItemProps as Record<string, unknown>}>
          {item.url ? <>
              <Link href={item.url} title={item.title} color="inherit" pr="8px">
                <Typography variant="body2" color="inherit" {...titleProps}>
                  {item.title}
                </Typography>
              </Link>

              <ChevronRightIcon pr="8px" alt={`breadcrumb-${index}`} />
            </> : <Typography variant="body2" color="inherit" pr="8px" {...titleProps}>
              {item.title}
            </Typography>}
        </LI>)}
    </Component>;
};