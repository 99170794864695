import React from 'react';
import { SvgIcon, SvgIconProps } from '../../wag-react/next-components/icons';
type TrainingIconProps = SvgIconProps;
export const TrainingIcon = (props: TrainingIconProps) => <SvgIcon viewBox="0 0 69 47" {...props} data-sentry-element="SvgIcon" data-sentry-component="TrainingIcon" data-sentry-source-file="TrainingIcon.tsx">
    <defs data-sentry-element="defs" data-sentry-source-file="TrainingIcon.tsx">
      <path id="a" d="M.045.08H9.77V10.9H.045z" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      <path id="c" d="M0 .053h12.913V11.6H0z" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
    </defs>
    <g fill="none" fillRule="evenodd" data-sentry-element="g" data-sentry-source-file="TrainingIcon.tsx">
      <path d="M26.444 13.236l-.634-.498c-1.6-1.255-1.9-3.603-.646-5.218l4.004-5.197a3.67 3.67 0 015.167-.653l.635.498c1.6 1.255 1.9 3.603.646 5.218l-4.004 5.197a3.672 3.672 0 01-5.17.652" fill="#4669b2" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      <g transform="translate(24.895 .403)" data-sentry-element="g" data-sentry-source-file="TrainingIcon.tsx">
        <path d="M1.243 10.252a3.17 3.17 0 01-.546-4.407l3.5-4.556a3.1 3.1 0 014.375-.55 3.17 3.17 0 01.546 4.407L5.608 9.7a3.1 3.1 0 01-4.365.551" fill="#3798e4" mask="url(#b)" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      </g>
      <path d="M36.536 11.967c4.323 3.173 4.45 10.432.285 16.213-3.33 4.623-9.01 6.655-12.872 5.858l-.004.007s-4.52-1.52-11.033 8.403l-.896-4.523 6.64-9.325c-1.07-3.542-.302-8.007 2.51-11.91 4.165-5.782 11.045-7.895 15.367-4.722z" fill="#4669b2" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      <path d="M13.81 8.77c2.95-4.736 8.117-4.416 8.117-4.416l14.08 7.256c-3.953-1.17-8.282 5.354-8.282 5.354L12.017 37.926l-10.963-6.33.038-.06-1.09-.63L13.81 8.77z" fill="#3798e4" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      <g transform="translate(0 30.849)" data-sentry-element="g" data-sentry-source-file="TrainingIcon.tsx">
        <path fill="#616a9b" mask="url(#d)" d="M0 .053l2.266 5.214 10.647 6.33-.896-4.523z" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      </g>
      <path d="M22.798 17.216c1.062-1.888 2.486-3.347 4.27-4.376l-9.65-4.984s-1.276 0-3.59 4.36l8.967 5z" fill="#4669b2" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
      <path d="M31.844 4.92c1.83 1.265 33.588 8.22 30.664 25.038-3.532 20.315-32.768 16.436-32.72 9.898.1-13.444 41.72-.737 36.944-20.892C64.14 8.033 45.718 7.76 36.388 6.31" stroke="#aaa" strokeWidth="2.394" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrainingIcon.tsx" />
    </g>
  </SvgIcon>;