import React from 'react';
import { Breadcrumb, Props as BreadcrumbProps, Breadcrumbs } from '../../wag-react/components/Breadcrumbs';
import { BackgroundImage, Props as BackgroundImageProps } from '../BackgroundImage';
import { Box } from '../Box';
import { Button, ButtonProps } from '../Button';
import { Container, ContainerProps } from '../Container';
import { Typography, TypographyProps } from '../Typography';
export type Props = {
  breadcrumbItems?: Breadcrumb[];
  breadcrumbProps?: Partial<BreadcrumbProps>;
  containerProps?: Omit<ContainerProps, 'children'>;
  ctaButtonBg?: string;
  ctaButtonLink?: string;
  ctaButtonText?: string;
  ctaButtonProps?: ButtonProps;
  ctaContainerProps?: Omit<ContainerProps, 'children'>;
  darkText?: boolean;
  subtitleText?: string;
  subtitleTextProps?: Omit<TypographyProps, 'children'>;
  titleText?: string;
  titleTextProps?: Omit<TypographyProps, 'children'>;
  ctaBlock?: ButtonProps['children'];
  onCtaButtonClick?: ButtonProps['onClick'];
} & Required<Pick<BackgroundImageProps, 'backgroundImage'>> & Omit<BackgroundImageProps, 'children'>;
export type HeroHeaderSectionProps = Props;
export const HeroHeaderSection = ({
  backgroundImage,
  breadcrumbItems,
  breadcrumbProps,
  ctaButtonBg = 'wagGreen',
  ctaButtonText,
  ctaButtonLink,
  ctaButtonProps,
  ctaContainerProps,
  containerProps,
  darkText,
  subtitleText,
  subtitleTextProps,
  titleText,
  titleTextProps,
  ctaBlock,
  onCtaButtonClick,
  ...rest
}: Props) => <BackgroundImage backgroundImage={backgroundImage} height={['516px', '448px', '448px', '756px']} {...rest} data-sentry-element="BackgroundImage" data-sentry-component="HeroHeaderSection" data-sentry-source-file="index.tsx">
    <Container pt={['32px', '48px']} {...containerProps} data-sentry-element="Container" data-sentry-source-file="index.tsx">
      {breadcrumbItems && breadcrumbItems.length && <Breadcrumbs variant="ul" mt={0} mb="4px" list={breadcrumbItems} {...breadcrumbProps} />}

      <Typography color={darkText ? 'medGray' : 'white'} m={0} mx={['auto', 0]} maxWidth={['268px', '436px']} textAlign={['center', 'left']} variant="h1" {...titleTextProps} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {titleText}
      </Typography>
      <Typography color={darkText ? 'medGray' : 'white'} fontWeight={3} maxWidth={['100%', '288px', '552px']} mx={['auto', 0]} mb={0} mt="8px" textAlign={['center', 'left']} variant="h3" {...subtitleTextProps} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {subtitleText}
      </Typography>

      {ctaBlock || ctaButtonText && <Box mt={['16px', '24px']} mx={['auto', 0]} width={['288px', '240px']} {...ctaContainerProps}>
            <Button href={ctaButtonLink} bg={ctaButtonBg} size="large" variant="contained" width={1} onClick={onCtaButtonClick} {...ctaButtonProps}>
              {ctaButtonText}
            </Button>
          </Box>}
    </Container>
  </BackgroundImage>;