import { createCanonicalUrl } from '../utils/createCanonicalUrl';
import { useRouter } from './useRouter';
export const useCanonicalUrl = () => {
  const {
    path
  } = useRouter();
  const canonicalUrl = createCanonicalUrl(path);
  return {
    canonicalUrl
  };
};