import React from 'react';
import { BackgroundProps, FlexboxProps, LayoutProps, SpaceProps, BackgroundColorProps, BorderProps, ColorProps, GridProps, PositionProps, ShadowProps, TypographyProps } from 'styled-system';
import { Link } from '../../core-components/link';
import { Image } from '../../core-components/image';
import appStoreSvg from './assets/app-store.svg';
import playStoreSvg from './assets/play-store.svg';
import { Distribution, URL } from './constants';
type StyledSystemProps = BackgroundProps & BackgroundColorProps & BorderProps & ColorProps & FlexboxProps & GridProps & LayoutProps & PositionProps & ShadowProps & SpaceProps & TypographyProps;
export type Props = StyledSystemProps & JSX.IntrinsicElements['a'] & {
  distribution: keyof typeof Distribution;
};
const DistributionPlatform = Object.freeze({
  [Distribution.AppStore]: {
    iconUrl: appStoreSvg,
    url: URL.AppStore
  },
  [Distribution.PlayStore]: {
    iconUrl: playStoreSvg,
    url: URL.PlayStore
  },
  [Distribution.AppStorePremium]: {
    iconUrl: appStoreSvg,
    url: URL.AppStorePremium
  },
  [Distribution.PlayStorePremium]: {
    iconUrl: playStoreSvg,
    url: URL.AppStorePremium
  }
});
export const AppDownloadButton = ({
  distribution,
  ...rest
}: Props) => {
  const distributionPlatform = DistributionPlatform[distribution];
  return <Link target="_blank" href={distributionPlatform.url} {...rest} data-sentry-element="Link" data-sentry-component="AppDownloadButton" data-sentry-source-file="AppDownloadButton.tsx">
      <Image src={distributionPlatform.iconUrl} alt="distribution-platform-icon-img" width={1} hover data-sentry-element="Image" data-sentry-source-file="AppDownloadButton.tsx" />
    </Link>;
};