import React from 'react';
import { SvgIcon, SvgIconProps } from '../../wag-react/next-components/icons';
type DropInIconProps = SvgIconProps;
export const DropInIcon = (props: DropInIconProps) => <SvgIcon viewBox="0 0 38 36" {...props} data-sentry-element="SvgIcon" data-sentry-component="DropInIcon" data-sentry-source-file="DropInIcon.tsx">
    <g fill="none" fillRule="evenodd" data-sentry-element="g" data-sentry-source-file="DropInIcon.tsx">
      <path d="M-5-6h48v48H-5z" data-sentry-element="path" data-sentry-source-file="DropInIcon.tsx" />
      <path opacity=".16" d="M-2-3h42v42H-2z" data-sentry-element="path" data-sentry-source-file="DropInIcon.tsx" />
      <g transform="translate(.25)" fillRule="nonzero" data-sentry-element="g" data-sentry-source-file="DropInIcon.tsx">
        <circle fill="#DBED5C" cx="16.273" cy="16.273" r="16.273" data-sentry-element="circle" data-sentry-source-file="DropInIcon.tsx" />
        <path d="M8.713 12.057c-.268.74-.607 1.485-.966 2.255-.876 1.91-1.782 3.877-1.642 6.01.223 3.408 2.807 6.627 6.302 7.804a9.863 9.863 0 003.121.448c3.436 0 7.675-1.273 10.067-3.737 3.48-3.573 5.124-10.64 5.273-15.764a16.388 16.388 0 00-1.532-2.495c.439 4.59-1.017 13.368-4.792 17.245-3.03 3.11-9.026 3.82-11.67 2.924-2.895-.987-5.129-3.724-5.305-6.51-.115-1.761.677-3.482 1.513-5.305.354-.772.721-1.572 1.012-2.37 1.131-3.134 1.71-4.923 1.46-8.545a5.45 5.45 0 00-1.067-2.956c-.478.182-.945.384-1.399.609.605.676.958 1.54 1 2.447.23 3.322-.285 4.922-1.375 7.94z" fill="#A2C23F" data-sentry-element="path" data-sentry-source-file="DropInIcon.tsx" />
        <path d="M29.512 24.723c.751-.968 1.814-2.422 2.6-2.848.958-.52 2.064-.949 3.142-.174.849.6 1.665 1.833 1.347 2.822-.5 1.56-1.854 2.436-1.789 3.31.066.875 1.392 2.173 1.79 3.024.466.997.965 2.432-.158 3.298-1.122.865-2.432.965-3.529.424-.749-.373-1.871-1.362-2.612-1.85-.252-.168-10.595-.344-10.947.012-.386.382-2.495 2.483-3.418 2.683-.923.199-2.08.284-3.124-.637s-1.26-2.434-.848-2.905c.411-.471 1.933-2.1 1.797-2.958-.136-.857-1.38-1.377-2.092-2.464-.713-1.086-1.034-2.711-.331-3.475.702-.764 2.242-1.437 3.359-1.119.594.172 2.238 1.965 2.78 2.583.396.452 11.937.399 12.033.274z" fill="#CE8E51" data-sentry-element="path" data-sentry-source-file="DropInIcon.tsx" />
        <circle fill="#A36A3B" cx="29.196" cy="28.865" r="1.199" data-sentry-element="circle" data-sentry-source-file="DropInIcon.tsx" />
        <circle fill="#A36A3B" cx="24.156" cy="28.867" r="1.199" data-sentry-element="circle" data-sentry-source-file="DropInIcon.tsx" />
        <circle fill="#A36A3B" cx="19.116" cy="28.795" r="1.199" data-sentry-element="circle" data-sentry-source-file="DropInIcon.tsx" />
      </g>
    </g>
  </SvgIcon>;