import React from 'react';
import { SvgIcon, SvgIconProps } from '../../wag-react/next-components/icons';
type SittingIconProps = SvgIconProps;
export const SittingIcon = (props: SittingIconProps) => <SvgIcon viewBox="0 0 42 32" {...props} data-sentry-element="SvgIcon" data-sentry-component="SittingIcon" data-sentry-source-file="SittingIcon.tsx">
    <g fill="none" fillRule="evenodd" data-sentry-element="g" data-sentry-source-file="SittingIcon.tsx">
      <path d="M-3-12h48v48H-3z" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
      <path d="M0-9h42v42H0z" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
      <g transform="translate(0 .75)" fillRule="nonzero" data-sentry-element="g" data-sentry-source-file="SittingIcon.tsx">
        <path d="M21.06 17.467v-.05L.309 14.435S3.839 25.374 4.545 26.71c.705 1.336 1.977 2.469 3.193 2.777 4.2 1.056 11.575 1.27 13.116 1.281 1.541.01 8.417-.15 12.834-1.228 1.225-.302 2.492-1.441 3.195-2.777.704-1.335 3.863-11.592 3.863-11.592L21.06 17.467z" fill="#6C78A9" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <ellipse fill="#6C78A9" cx="20.701" cy="13.429" rx="19.249" ry="6.259" data-sentry-element="ellipse" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M39.254 15.872l-.205-.133c-.876-2.461-2.74-3.256-4.987-3.914-1.36-.397-4.703.04-6.508.436l-.101-.012c-.876-.71-3.04-2.08-5.554-2.08-2.295 0-4.323.93-6.157 1.51-2.033-.476-5.186-.974-7.327-.453-2.961.72-4.118 1.81-6.107 4.115-.052.06.255.285.52.466-.175.115-.343.23-.5.348-.735.55 1.358 1.802 6.664 3.397 1.694 1.192 3.879 4.118 4.139 6.122 2.21.801 4.976.637 7.566.637 2.872 0 5.22.34 7.599-.637.812-1.281 2.431-5.268 3.833-6.255 5.307-1.655 8.022-2.94 7.125-3.547z" fill="#B9C4E0" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M16.265 16.504c-.055.139.496-3.04-1.09-4.829 1.62.801 2.285 2.795 1.485 4.502l-.395.327zM25.46 16.287c-.145-.863-.205-1.962.257-2.706.462-.744.695-.786 1.399-1.14-.566.674-.66.67-.941 1.58-.282.91-.338 1.9.087 2.755l-.868-.62" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M26.095 16.502c1.35-1.13 3.309-1.783 5.136-1.822 1.827-.04 3.968.775 5.346 1.848-3.247-1.153-7.267-1.257-10.335.302" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M26.06 16.498c.71.754 1.17 1.695 1.071 2.846-.093 1.049-.225 2.378-.938 3.32-.001 0 .882-3.42-.133-6.166z" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M26.24 17.019c-.631-.928-2.241-1.32-3.36-1.497a11.452 11.452 0 00-6.37.827c.222-.661 2.197-1.852 4.335-1.727 3.951.233 4.852 1.174 5.38 2.208M14.352 17.746a8.249 8.249 0 00-4.673-2.457 8.161 8.161 0 00-5.228.857c.074-.356 1.694-1.225 2.346-1.358 2.735-.576 4.328-.398 6.475 1a14.14 14.14 0 011.853 1.587c-.01.212-.03.792-.205.872l-.568-.501z" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M16.592 16.305c-.777.697-1.123 1.933-1.095 3.093.027 1.16.096 2.148.418 3.22a7.625 7.625 0 01-.972-2.112 4.253 4.253 0 01.015-2.692c.315-.842 1.007-1.5 1.772-1.553" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M13.835 20.682c-.546-.187-1.803-.34-2.833.015-.153.052.083.684.212.634 1.642-.637 3.48.267 4.708 1.71.006 0-.408-1.782-2.087-2.359z" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M15.869 23.079c.653-1.855 3.33-2.667 5.184-2.566 1.855.101 4.387.845 4.983 2.55 0 0-1.757-1.794-5.26-1.55-3.842.27-4.907 1.566-4.907 1.566zM26.098 22.913c1.157-.956 2.856-1.865 4.326-1.535.357.08.101 1.272-.567 1.041-1.39-.363-2.378.159-3.766.53l.007-.036z" fill="#9DA8CB" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M20.772 5.683C9.295 5.683 0 9.107 0 13.331c0 1.447.817 2.91 2.92 4.181 0 0 5.305 2.396 5.827 2.618.352.151.973.481 1.256 1.056.397.805 1.676 3.435 2.254 4.493.684 1.25 1.434 1.168 2.166 1.195.731.027 12.241.025 13.242.025.928 0 1.198-.919 1.581-1.622.294-.541 2.002-3.244 2.72-4.347.549-.84.803-1.106 1.147-1.272.557-.272 4.946-2.03 5.454-2.252.369-.185 3.077-1.509 2.976-4.074-.167-4.222-9.3-7.649-20.771-7.649zm17.192 10.512c-.778.452-.897.416-2.504 1.056-1.377.552-1.78.727-2.79 1.079-.957.332-1.484.916-1.895 1.68-.322.604-3.192 5.25-3.192 5.25l-13.866-.106s-1.496-3.09-2.092-4.458c-.64-1.476-1.862-1.683-2.321-1.877-.514-.218-4.687-1.897-5.589-2.455-1.441-.871-2.263-1.878-2.263-2.934 0-3.457 8.619-6.26 19.25-6.26 10.63 0 19.251 2.802 19.251 6.26 0 .992-.72 1.93-1.99 2.765z" fill="#919DC5" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <ellipse fill="#FABC44" cx="4.831" cy="26.764" rx="4.53" ry="4.467" data-sentry-element="ellipse" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M2.727 25.606c-.075.204-.17.406-.27.619-.243.524-.495 1.065-.459 1.65.063.936.784 1.812 1.756 2.144a2.8 2.8 0 00.869.122c.956 0 2.142-.352 2.802-1.026.968-.981 1.426-2.92 1.467-4.328a4.41 4.41 0 00-.425-.687c.122 1.26-.283 3.67-1.333 4.734-.845.854-2.514 1.049-3.247.803-.807-.272-1.428-1.023-1.478-1.788-.033-.483.188-.956.42-1.455.1-.211.2-.432.282-.65.315-.86.476-1.359.406-2.344a1.48 1.48 0 00-.297-.812 4.63 4.63 0 00-.389.168c.168.185.267.422.278.671.065.912-.08 1.35-.382 2.18z" fill="#000" opacity=".2" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
        <path d="M27.027 13.112c.68.58.673 1.403 1.91 1.435 2.506.062 1.582-2.949 1.582-2.949.058-.12.269-1.726 2.407-3.984.765-.811 2.77-2.58 2.77-2.58a2.293 2.293 0 002.258-.632c.629-.666.574-1.435.346-1.876-.491-.95-1.287-.866-1.44-.98-.153-.115-.257-1.554-1.73-1.546-.88 0-1.243.22-1.655.738-.94 1.166-.328 2.196-.328 2.196.054.647-.134 1.261-2.001 3.549-.707.864-3.084 2.933-3.084 2.933s-2.853.092-2.754 2.224c.044 1.069 1.565 1.346 1.719 1.472z" stroke="#F9CD46" fill="#FAE7A8" data-sentry-element="path" data-sentry-source-file="SittingIcon.tsx" />
      </g>
    </g>
  </SvgIcon>;