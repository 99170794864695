import React from 'react';
import { SvgIcon, SvgIconProps } from '../../wag-react/next-components/icons';
type BoardingIconProps = SvgIconProps;
export const BoardingIcon = (props: BoardingIconProps) => <SvgIcon viewBox="0 0 40 40" {...props} data-sentry-element="SvgIcon" data-sentry-component="BoardingIcon" data-sentry-source-file="BoardingIcon.tsx">
    <g fill="none" fillRule="evenodd" data-sentry-element="g" data-sentry-source-file="BoardingIcon.tsx">
      <path d="M-5-4h48v48H-5z" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
      <path d="M-2-1h42v42H-2z" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
      <g fillRule="nonzero" data-sentry-element="g" data-sentry-source-file="BoardingIcon.tsx">
        <path d="M11.055 37.294s1.648-8.587 2.146-12.554c.62-4.903.45-12.697.45-12.697s.054-2.939 1.057-2.8c.73.095 1.024 1.606 1.251 2.916.228 1.31 1.046 8.41.536 12.878-.38 3.351-3.074 12.588-3.074 12.588s-.653.338-1.288.222a2.306 2.306 0 01-1.078-.553zM27.16 37.294s-1.65-8.587-2.15-12.554c-.62-4.903-.45-12.697-.45-12.697s-.053-2.939-1.057-2.8c-.732.095-1.023 1.606-1.251 2.916-.228 1.31-1.058 8.444-.552 12.912.382 3.351 3.084 12.554 3.084 12.554s.655.338 1.289.222a2.296 2.296 0 001.086-.553z" fill="#616A9B" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
        <path d="M19.117 7.068c2.606.063 4.03 2.378 4.03 2.378l.414-.103s-.873-4.037-4.384-4.037c-3.915 0-4.57 3.998-4.57 3.998s.483.578.517.554c.035-.025.824-2.865 3.993-2.79z" fill="#A5B3D7" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
        <path d="M2.908 16.91s2.633-1.474 4.653-1.336c2.02.137 4.898.685 4.898.685s3.49-.548 6.49-.548 4.898 1.029 4.898 1.029 4.592-1.238 6.673-1.098c2.081.139 5.174 1.237 5.174 1.237s1.439 6.857 1.439 7.955c0 1.098-1.072 13.507-1.072 13.507s-.047.36-1.454.429c-1.407.068-4.76-.497-5.863-.497H19.08s-8.205-.273-9.252-.137c-1.046.136-3.428 1.028-4.715.96-1.287-.068-2.53-.605-2.571-.72-.184-.514-1.286-11.896-1.286-11.896l1.653-9.57z" fill="#848EC1" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
        <path d="M23.934 38.338s.396-9.007.321-13.12c-.09-5.085-1.38-12.954-1.38-12.954s-.368-2.982.642-3.034c.736-.037 1.242 1.441 1.657 2.726.414 1.285 1.42 8.537 1.559 13.16.103 3.467-.415 13.12-.415 13.12s-.598.464-1.242.464a2.178 2.178 0 01-1.142-.362zM14.28 38.22s-.38-8.889-.306-13.002c.092-5.085 1.38-12.954 1.38-12.954s.368-2.982-.641-3.034c-.736-.037-1.243 1.441-1.657 2.726-.415 1.285-1.423 8.537-1.56 13.16-.104 3.467.397 13 .397 13s.581.498 1.225.498a2.135 2.135 0 001.162-.395z" fill="#A5B3D7" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
        <path d="M2.908 16.91S.382 21.367.382 26.784c0 6.519 2.072 11.588 2.072 11.588s.05-5.982 0-10.867c-.05-4.886.454-10.595.454-10.595zM35.694 16.876s2.526 4.457 2.526 9.874c0 6.517-2.072 11.588-2.072 11.588s-.146-6.302-.146-11.176c-.003-4.835-.308-10.286-.308-10.286z" fill="#6F76A8" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
        <path fill="#F9CD46" d="M1.36 8.946l2.398.46.654 2.765.473-2.784 2.355-.65-2.432-.493-.65-2.658-.412 2.674zM27.4 10.35l2.056.392.56 2.37.406-2.386 2.018-.557-2.085-.423-.557-2.279-.352 2.292zM29.92 5.223l3.77.72 1.026 4.347.742-4.376 3.702-1.022-3.822-.775-1.02-4.176-.647 4.2z" data-sentry-element="path" data-sentry-source-file="BoardingIcon.tsx" />
      </g>
    </g>
  </SvgIcon>;